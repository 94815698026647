<ng-container *ngIf="id">
  <button
    *ngIf="state === DocumentState.Open"
    mat-menu-item
    (click)="toggleState.emit(id); bottomSheet.dismiss('toggle-state')">
    <div class="flex flex-row items-center gap-1">
      <ng-icon
        name="heroArchiveBox"
        class="mr-2 text-xl">
      </ng-icon>
      <span>{{ 'i18n.Common.archiveDocument' | translate }}</span>
    </div>
  </button>

  <button
    *ngIf="state === DocumentState.Closed"
    mat-menu-item
    (click)="toggleState.emit(id); bottomSheet.dismiss('toggle-state')">
    <div class="flex flex-row items-center gap-1">
      <ng-icon
        name="heroArchiveBox"
        class="mr-2 text-xl">
      </ng-icon>
      <span>{{ 'i18n.Common.activateDocument' | translate }}</span>
    </div>
  </button>

  <button
    mat-menu-item
    (click)="edit.emit(id); bottomSheet.dismiss('edit')">
    <div class="flex flex-row items-center gap-1">
      <ng-icon
        name="heroPencil"
        class="mr-2 text-xl">
      </ng-icon>
      <span>{{ 'i18n.Common.edit' | translate }}</span>
    </div>
  </button>

  <button
    mat-menu-item
    class="show-share-button"
    [disabled]="disableShare"
    [class.disable-hover]="disableShare"
    (click)="share.emit(id); bottomSheet.dismiss('share')">
    <div class="flex flex-row items-center gap-1">
      <ng-icon
        name="heroShare"
        class="mr-2 text-xl">
      </ng-icon>
      <span>{{ 'i18n.Common.share' | translate }}</span>
    </div>
  </button>

  <button
    mat-menu-item
    (click)="remove.emit(id); bottomSheet.dismiss('remove')">
    <div class="flex flex-row items-center gap-1 text-red-600">
      <ng-icon
        name="heroTrash"
        class="mr-2 text-xl">
      </ng-icon>
      <span>{{ 'i18n.Common.delete' | translate }}</span>
    </div>
  </button>
</ng-container>
